.loading-wrapper {
  @apply fixed w-full bg-neutral/90 flex items-center justify-center z-[99999] min-h-full left-0 top-0;
}
.spinner {
  @apply animate-[rotate_2s_linear_infinite] z-[2] absolute w-[50px] h-[50px];
  -webkit-animation: rotate 2s linear infinite;
}
.spinner .path {
  @apply stroke-current animate-[dash_1.5s_ease-in-out_infinite];
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
}
.text-primary-100 {
  @apply text-primary;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
