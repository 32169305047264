.close {
  @apply absolute right-2.5 top-2.5;
}
.header {
  @apply text-baseColor text-2xl;
}
.content {
  @apply text-[21px];
}
.overlay-base {
  @apply text-center fixed  flex justify-center items-center p-6 inset-0;
  outline: 0;
  overflow: overlay;
}
.overlay-after {
  @apply backdrop-blur-[14px] opacity-100 z-[1000];
}
.overlay-before {
  @apply bg-[rgba(0,0,0,0)] opacity-0;
}
.content-base {
  @apply relative items-center w-[0%] bg-neutral shadow-[0px_3px_10px_#00000029] rounded m-auto border-0;
  outline: 0;
}
.content-after {
  @apply max-w-[736px] w-full bg-[$white] px-[105px] py-[86px];
}
.content-before {
  @apply w-[0%] h-[0%] bg-transparent;
}
.popup-btn-block {
  @apply flex justify-center mt-10;
}
@media (max-width: 768px) {
  .content-after {
    @apply px-5 py-[86px];
  }
  .popup-btn-block {
    @apply grid gap-5 justify-center justify-items-center;
  }
  .popup-btn-block button {
    @apply m-0 px-12 py-2.5;
  }
}
.current-tag-area {
  @apply text-left;
}
.current-tag-area .search-container {
  @apply flex relative w-full;
}
.current-tag-area .search-container .search-transition {
  @apply transition-all duration-[0.5s] ease-[ease-in];
}
.current-tag-area .search-container .search-input {
  @apply w-full rounded-lg h-[50px] border  pl-5  border-solid;
  outline: none;
}
.current-tag-area .search-container .search-btn {
  @apply absolute right-[10%];
}
.current-tag-area .tag-count {
  @apply text-center mx-0 my-[35px];
}
.current-tag-area .tag-count p {
  @apply text-baseColor text-xl font-medium m-0;
}
.current-tag-area .tag-count p span {
  @apply font-black;
}

.current-tag-area .table-area table {
  @apply w-full;
}
.current-tag-area .table-area table tbody {
  @apply w-full;
}
.current-tag-area .table-area table tbody tr:nth-child(odd) td {
  @apply bg-primary bg-opacity-15;
}
.current-tag-area .table-area table tbody tr:nth-child(even) td {
  @apply bg-neutral;
}
.current-tag-area .table-area table tbody td {
  @apply w-full px-[25px] py-5;
}
.current-tag-area .tag-pagination .pagination {
  @apply mt-5;
}
.current-tag-area .search-input::placeholder {
  @apply text-baseColor text-opacity-40 font-normal text-base;
}
.tag-list-block {
  @apply max-h-[250px] overflow-auto grid grid-cols-[repeat(auto-fit,minmax(145px,max-content))] gap-4;
}
