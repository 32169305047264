.input-group {
  @apply relative;
}
.input-group label,
.input-group span,
.dropdown-label,
.header-label {
  @apply mb-3 block break-words text-left font-medium  text-baseColor;
}
/* .input-group label sup,
.input-group span sup,
.dropdown-label sup {
  @apply absolute top-[7px] pl-[4px];
} */
.input-group input:disabled::placeholder {
  @apply !text-baseColor !text-opacity-60;
}

.input-group .rmdp-container {
  @apply w-full;
}
.input-group input:disabled,
.input-group input.disabled,
.input-group input .PhoneInput--disabled input:disabled {
  @apply cursor-not-allowed !bg-disabled hover:border-outlineColor/50;
}
.css-10lk5e0-control {
  @apply !bg-disabled;
}
.input-group input,
.dropDown {
  @apply relative min-h-[48px] rounded-lg border border-outlineColor bg-neutral py-2 pl-3 pr-4  text-baseColor  hover:border-primary active:border-primary;
  box-sizing: border-box;
}
.input-group input:focus {
  @apply border-2 border-primary outline-none;
}

.input-group .w-100,
.w-dropdown-100,
input {
  @apply w-full;
}
/* .form-sm-txt {
  @apply absolute left-0 right-0 mt-1;
} */
.form-sm-txt.left {
  @apply text-left;
}
.form-sm-txt.center {
  @apply text-center;
}
.form-sm-txt a {
  @apply ml-1;
}
.form-error-msg {
  @apply mt-2 text-left text-sm text-error;
}
.form-error-msg p {
  @apply text-error;
}
.input-group .textarea-wrap .input-error,
.input-group input.input-error {
  @apply border border-error;
}

.error-info-box {
  @apply grid rounded-lg border-2 bg-card p-4;
}
.error-info-box li {
  @apply m-0;
}
.input-helper-text {
  @apply mt-2.5 font-body font-medium text-baseColor/70;
}

.tooltip-link {
  @apply text-[0.625rem]  text-neutral;
}

.document-upload input {
  @apply opacity-0;
}
.document-upload .uploadInput span {
  @apply absolute px-3 py-[12px] text-baseColor text-opacity-75;
}

.document-upload .uploadInput {
  @apply relative rounded-lg border border-outlineColor/50 bg-neutral hover:!border-primary active:!border-2 active:!border-primary;
}

.document-upload input[type="file"]::file-selector-button {
  width: 0px;
  color: transparent;
}

/* Faked label styles and icon */
.document-upload input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  top: 14px;
  right: 16px;
  height: 20px;
  width: 20px;
  content: "";
}

.document-upload input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  top: 15px;
  left: 0px;
  content: "";
  @apply text-baseColor;
}

/* ------- From Step 1 ------- */

/* file upload button */
.document-upload input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 5px 8px;
  height: 48px;
  cursor: pointer;
  background-color: white;
  border: none !important;
  box-shadow: none !important;
  margin-right: 0px;
  transition: background-color 200ms;
}

/* file upload button hover state */
/* .document-upload input[type='file']::file-selector-button:hover {
  border-radius: 100px;
  min-height: 40px;
  display: none;
  background: red;
} */
.document-upload input[type="file"]::file-selector-button {
  @apply rounded-full;
  visibility: hidden;
}

input[type="password"] {
  -webkit-text-security: disc;
  font-family: Verdana;
  letter-spacing: 0.125em;
}
/* .document-upload::-webkit-file-upload-button {
  visibility: hidden;
} */
/*
.document-upload::before {
  content: 'your wording';
} */

.toggle-pass-icon {
  @apply absolute cursor-pointer text-baseColor z-50;
  top: 46px;
  right: 14px;
}

.textarea-wrap textarea {
  @apply relative w-full rounded-lg border border-outlineColor bg-neutral p-3 tracking-tighter text-baseColor hover:border-primary focus-visible:border-primary active:border-primary;
  min-height: 98px;
}
.textarea-wrap textarea:focus {
  @apply border-2 border-primary outline-none;
}
.textarea-wrap textarea {
  letter-spacing: 0px;
}

/* .textarea-wrap textarea::placeholder {
  @apply text-baseColor text-opacity-60 !important;
} */

textarea::-webkit-scrollbar {
  width: 7px;
}
textarea::-webkit-scrollbar-thumb {
  @apply bg-card;
  /* background: #c8ccd1; */
  border-radius: 10px;
  height: 20px;
}
textarea {
  resize: none;
}
.textarea-wrap .quill .ql-toolbar.ql-snow {
  @apply rounded-tl-md rounded-tr-md border;
}
.textarea-wrap .quill .ql-container.ql-snow {
  @apply rounded-bl-md rounded-br-md;
}
.footer-textarea {
  @apply absolute bottom-1 mx-1 h-10 min-h-[40px] w-full  bg-neutral;
}
.footer-textarea .emoji {
  @apply text-right;
}
.ql-snow .ql-picker.ql-header {
  @apply !w-28;
}

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  @apply fill-current stroke-current text-baseColor;
}
.ql-snow .ql-stroke {
  @apply !stroke-current !text-baseColor;
}
.ql-editor.ql-blank::before {
  @apply !text-baseColor !text-opacity-50;
}
