.footer-menu-block {
  @apply fixed z-50 flex w-full items-center bg-neutral min-h-[52px] border-t-primary border-t border-solid bottom-0;
}
@media (min-width: 569px) {
  .footer-menu-block {
    @apply hidden;
  }
}
.footer-bento-menu-block {
  @apply block cursor-pointer;
}
.footer-bento-menu-section {
  @apply border min-h-[250px] border-solid border-outlineColor/50 bottom-[52px] absolute z-30 grid w-full grid-cols-2 overflow-hidden bg-neutral left-0;
}
.footer-bento-menu-section a,
.footer-bento-menu-section a:hover {
  @apply text-[#072f33] min-w-[117px] min-h-[117px] border text-base leading-6 border-solid border-outlineColor/50;
}
.bento-list {
  @apply flex h-full w-full flex-col items-center justify-center;
}
.footer-menu-block svg {
  @apply fill-[#390a65];
}
svg {
  @apply block align-middle;
}
.bento-menu-section a span,
.footer-bento-menu-section a span {
  @apply text-center pt-3 px-1;
  word-break: break-word;
}
