.main-text {
  @apply grid max-w-screen-md gap-4 text-center mx-auto pb-10 px-4;
}
.outer-container {
  @apply w-full bg-neutral mx-auto  rounded-lg;
  --tw-bg-opacity: 1;
}
.dash-tab-grey {
  @apply block border p-5 rounded-md;
}
.tabs-area .custom-tab .react-tabs ul {
  @apply z-[1000];
  list-style: none;
}
.tabs-area .custom-tab .react-tabs ul li {
  @apply bg-neutral  text-base leading-6 font-bold border cursor-pointer mb-3 px-3 py-4 border-solid border-outlineColor/50 last:mb-0;
}
.tabs-area .custom-tab .react-tabs ul li .label-area {
  @apply flex items-center;
}
.tabs-area .custom-tab .react-tabs {
  @apply grid grid-cols-[1fr_2fr] gap-10;
}
.tabs-area .custom-tab .react-tabs ul li.react-tabs__tab--selected {
  @apply bg-[#035faf] text-neutral;
}
.tabs-area .custom-tab .react-tabs ul li button {
  @apply bg-transparent bg-none border-[none];
  -webkit-appearance: button;
  outline: none !important;
}
.tabs-area .custom-tab .react-tabs .custom-panel {
  @apply px-24 border-l-outlineColor border-l border-solid;
}
.tabs-area .custom-tab .react-tabs ul li.react-tabs__tab--selected button {
  @apply text-neutral;
}
.resources-button {
  @apply max-w-[400px] z-[1000] mb-[30px] pl-8;
}
.resources-button button {
  @apply bg-[#1B67BC0D] min-w-[150px] min-h-[30px] text-primary font-semibold px-[15px] py-2.5 rounded-[10px] border-[none];
}
.tabs-area .custom-tab .react-tabs__tab-list {
  @apply mt-[60px];
}
.tabs-area.popup .custom-tab .react-tabs__tab-list {
  @apply mt-0;
}
.tabs-area.popup .custom-tab .react-tabs .custom-panel {
  @apply pl-0;
}
.resource-role-grid {
  @apply grid grid-cols-[1fr_2fr] gap-10 absolute max-w-full;
}
.right-tab {
  @apply px-20;
}
.right-btn {
  @apply flex justify-end;
}
.flex-center {
  @apply flex items-center;
}
.scope-block {
  @apply mb-2.5;
}
.scope-block h6 {
  @apply font-semibold text-lg mb-0;
}
.scope-block p {
  @apply text-base font-[14px];
}
.scope-description {
  @apply flex justify-between;
}
.scope-description button {
  @apply bg-transparent text-primary font-semibold border-[none];
}
.scope-section {
  @apply mt-10 mb-[30px] mx-0 border-b-outlineColor border-b border-solid;
}

.scope-btn {
  @apply z-[1000];
}
.roles.content-after {
  @apply max-w-[50%] p-[60px];
}
.scope-popup {
  @apply text-left;
}
.scope-popup .popup-title {
  @apply font-bold mb-5;
}
.scope-popup . label {
  @apply text-base font-normal;
}
.scope-popup . {
  @apply w-full;
}
.scope-popup . input {
  @apply text-sm w-full mb-[5px];
}
.scope-popup . .error-message {
  @apply text-sm text-error;
}
.mw-check-container {
  @apply flex relative cursor-pointer font-semibold text-base select-none whitespace-normal flex-col mb-3 pl-[45px];
  /* align-items: center; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.mw-check-container span {
  @apply text-xs font-medium;
}
.mw-check-container input {
  @apply absolute opacity-0 cursor-pointer h-6 w-6 left-0;
}
.mw-check-container .checkmark {
  @apply bg-neutral border absolute h-6 w-6 rounded-sm border-solid border-primary left-0;
}
.mw-check-container input:checked ~ .checkmark:after {
  @apply block w-1.5 h-[11px] rotate-45 border-solid border-[0_2px_2px_0] left-2 top-1;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}
.checkmark:after {
  @apply content-[""] absolute;
}
.checkbox-container {
  @apply pl-24 pr-2.5 py-2.5 hover:bg-neutral;
}
.m-b-45 {
  @apply mb-[45px];
}
.flex-between {
  @apply flex justify-between;
}
.addScope-btn {
  @apply relative;
}
.icon-position {
  @apply absolute z-[1000] right-5;
}
.icon-position svg path {
  @apply fill-neutral;
}
.input-area {
  @apply grow-0 shrink-0 basis-[30%];
}
