button:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px !important;
}
select:focus-visible {
  outline: -webkit-focus-ring-color auto 2px !important;
}
img:focus-visible,
svg:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
}
a:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
}
.primaryNav a:focus-visible {
  @apply outline-offset-[-9px];
  outline: -webkit-focus-ring-color auto 2px;
}
h1[tabindex="0"]:focus-visible,
h2[tabindex="0"]:focus-visible,
h3[tabindex="0"]:focus-visible,
h4[tabindex="0"]:focus-visible,
h5[tabindex="0"]:focus-visible,
h6[tabindex="0"]:focus-visible,
p[tabindex="0"]:focus-visible,
label[tabindex="0"]:focus-visible,
span[tabindex="0"]:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
}
li[tabindex="0"]:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
}
input[type="text"]:focus-visible,
input[type="searcf"]:focus-visible,
input[type="password"]:focus-visible {
  @apply outline-offset-0;
  outline: -webkit-focus-ring-color auto 0px;
}
input:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
}
textarea:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
}
input[type="checkbox"] + .checkmark:focus-visible,
input[type="radio"] + .checkmark:focus-visible,
input[type="checkbox"]:focus-visible + .faq-toggle {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
}

input[type="file"]:focus-visible {
  @apply outline-offset-8 opacity-100;
  outline: -webkit-focus-ring-color auto 2px;
}
*:focus {
  outline: none;
}
/* input[type="checkbox"]:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
} */
span[tabindex="0"]:focus-visible,
div[tabindex="0"]:focus-visible,
form[tabindex="0"]:focus-visible,
video[tabindex="0"]:focus-visible,
div[tabindex="1"]:focus-visible {
  @apply outline-offset-8;
  outline: -webkit-focus-ring-color auto 2px;
}
th[tabindex="0"]:focus-visible,
td[tabindex="0"]:focus-visible {
  @apply -outline-offset-4;
  outline: -webkit-focus-ring-color auto 2px;
}
button:focus-visible,
select:focus-visible {
  outline: #101010 auto 1px;
}
.table {
  @apply mb-0;
}
.layout {
  @apply mb-[52px];
}
.layout .layout-container {
  @apply max-w-[1220px] px-0 py-[57px];
}
.primary-text {
  @apply text-[$primary-blue];
}
.secondary-text {
  @apply text-secondary;
}
.app-sub-header {
  @apply mx-auto w-full font-semibold items-center justify-between gap-3 rounded-lg bg-primary bg-opacity-15 p-6 text-left md:flex-row lg:flex;
}
.multi-btn {
  @apply grid justify-end grid-cols-[repeat(auto-fit,minmax(170px,max-content))] gap-5;
}
@media (max-width: 768px) {
  .multi-btn {
    @apply justify-center;
  }
  .multi-btn button {
    @apply m-0;
  }
}

.mb-50 {
  @apply mb-[50px];
}
.tex-align-l {
  @apply text-left;
}
.ReactModal__Body--open {
  @apply overflow-hidden;
}
.blue-link {
  @apply text-primary cursor-pointer font-bold;
}
.mb-6 {
  @apply mb-1.5;
}
.marginMobile {
  @apply mx-5 my-0;
}
/* Video container */
video {
  @apply max-w-full;
}
iframe {
  @apply w-full max-w-[659px];
}
.pdf-container img {
  @apply max-w-full w-full;
}
#pdfviewer img {
  @apply max-w-full w-full h-auto;
}

/* 2 Button container */
.double-cta-space {
  @apply inline-block mr-5;
}

/* END */

/* Markdown text editor message */
.tox-notification--warning {
  @apply max-w-[285px] w-full m-auto;
}
/* END */

/* Edge browser */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  @apply hidden;
}
/* END */

/* Custom css time picker */
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  @apply left-[-25px];
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  @apply left-[-25px] top-[-7px];
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  @apply left-[-25px] top-0;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  @apply left-[-25px] -top-1.5;
}

/* END */

/* Search field */
.search-box {
  @apply bg-[url(../src/assets/images/search.svg)] bg-no-repeat bg-center w-[50px] h-[50px] absolute right-0;
}
.search-container {
  @apply border border-outlineColor/50 rounded-lg;
}
/* END */

/* Media query */
@media (max-width: 768px) {
  .app-sub-header .search-container:first-child {
    @apply text-end w-full mb-5;
  }
}
@media (max-width: 567px) {
  .double-cta-space {
    @apply block mr-0 mb-5 m-auto;
  }
  .app-sub-header .search-container:first-child {
    @apply text-end w-full mb-5;
  }
}
.custom-toast {
  @apply h-auto min-h-[127px];
}
/* END */
.tox .tox-toolbar--scrolling {
  @apply flex-wrap justify-between;
}
.view-container img {
  @apply max-w-full;
}
.client_credentials {
  @apply grid gap-[25px];
}
.file_upload_btn {
  @apply relative inline-flex items-center;
}
.Toastify__toast--success,
.Toastify__toast--error,
.Toastify__toast--info,
.Toastify__toast--warning {
  @apply flex min-h-[80px] items-center text-left !bg-neutral text-baseColor px-[3px] py-[5px];
}
.Toastify__toast--success .MuiSvgIcon-root {
  @apply text-[2.5rem] text-success;
}
.Toastify__toast--error .MuiSvgIcon-root {
  @apply text-[2.5rem] text-error;
}
.Toastify__toast--info .MuiSvgIcon-root {
  @apply text-[2.5rem] text-info;
}
.Toastify__toast--warning .MuiSvgIcon-root {
  @apply text-warning text-[2.5rem];
}
.Toastify__toast-icon {
  @apply block;
}
.Toastify__close-button {
  @apply items-center text-baseColor opacity-100;
}
.Toastify__toast-container {
  @apply min-w-[450px] z-[999999999999];
}
.Toastify__progress-bar {
  @apply bg-primary;
}
.Toastify__toast-body {
  @apply m-0 p-0 !bg-white;
}
.toast-position {
  @apply flex items-center;
}
.toast-position p {
  @apply mb-0;
}

.document-block {
  @apply grid gap-5 text-center;
}
.document-block nav {
  @apply flex px-10 w-full;
}
.document-block nav .pager {
  @apply flex w-full items-center justify-between gap-2;
}
.document-block nav .pager li {
  @apply mb-0;
}
.document-block nav .pager li button {
  @apply w-14 h-14 flex justify-center items-center text-white rounded-full bg-primary;
}
.react-pdf__Page{
  @apply !w-full flex justify-center;
}
.document-block canvas {
  @apply !border rounded-xl ;
}
.react-datepicker-wrapper{
  @apply w-full
}
.react-datepicker-wrapper span {
  @apply m-0
}
input[type="checkbox"] {
  @apply h-6 w-6 rounded-md appearance-none bg-slate-50 border cursor-pointer relative;
  -webkit-appearance: none;
  
}

input[type="checkbox"]:checked {
  @apply bg-primary border-primary;
}

input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  left: 8px;
  top: 4px;
  width: 6px;
  height: 12px;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.rdt_Pagination{
  @apply !border-none
}