.create-resource .progress-container .progress-bar {
  @apply bg-secondary;
}
.create-resource .custom-date-picker {
  @apply min-h-[48px] bg-neutral rounded-lg border border-outlineColor;
}
.create-resource .custom-date-picker span {
  @apply tracking-[0.48px] text-baseColor;
  font: italic normal normal 16px/19px Montserrat;
}
.create-resource .align-right {
  @apply text-right;
}
.view-resource .view-container {
  @apply py-[54px] bg-neutral;
}
.view-resource .view-container .updated-info {
  @apply p-[22px] bg-primary bg-opacity-15;
}
.view-resource .view-container .updated-info .title {
  @apply whitespace-normal;
  word-break: break-word;
}
.view-resource .view-container .note-info {
  @apply flex flex-wrap mb-[25px] p-[22px] bg-primary bg-opacity-15;
}
.view-resource .view-container .content-info {
  @apply flex-[0_0_50%] p-[22px];
}
.view-resource .view-container .title {
  @apply tracking-[1.4px] text-baseColor whitespace-normal;
  word-break: break-word;
}
.view-resource .view-container .file .pdf-viewer {
  @apply h-[700px] max-w-[600px] w-full;
}
.view-resource .view-container .file .image-viewer {
  @apply h-[700px] w-[600px];
}
.view-resource .view-container .file .video-viewer {
  @apply h-[500px] w-[600px] bg-baseColor;
}
.view-resource .p-relative {
  @apply relative;
}
.view-resource .review-container {
  @apply absolute max-w-[400px] w-full shadow-[0px_3px_9px_#00000029] rounded opacity-100 right-0 bottom-[90px] bg-neutral;
}
.view-resource .review-container .review-form {
  @apply p-[22px];
}
.list-container-table {
  @apply bg-neutral;
}
.list-container-table .horizontal-scrolll {
  @apply overflow-x-auto;
}
.list-container-table .scroll-content {
}

/* width */
.list-container-table ::-webkit-scrollbar {
  @apply h-2 rounded-[15px] bg-baseColor bg-opacity-60;
}

/* Handle */
.list-container-table ::-webkit-scrollbar-thumb {
  @apply rounded-[15px] bg-primary;
}

/* Handle on hover */
.list-container-table ::-webkit-scrollbar-thumb:hover {
  @apply bg-primary;
}
.list-container-table .resource-list {
  @apply table-fixed border border-outlineColor/50 !rounded-lg;
}
.list-container-table .resource-list thead {
  @apply border-[none];
}
.list-container-table .resource-list thead tr th {
  @apply bg-primary bg-opacity-10 text-baseColor tracking-[0.8px] relative align-middle  border-solid break-words;
}
.list-container-table .resource-list thead tr th {
  word-wrap: break-word;
}
.list-container-table .resource-list thead tr th p {
  @apply mb-0;
}
.list-container-table .resource-list thead tr th .resource-heading {
  @apply flex items-center min-h-[70px] justify-start;
}

.list-container-table .resource-list tbody td {
  @apply bg-neutral tracking-[0.8px] text-baseColor;
}
.list-container-table .resource-list img {
  @apply cursor-pointer;
}
.search-container {
  @apply flex relative;
}
.search-container .search-transition {
  @apply transition-all duration-[0.5s] ease-[ease-in];
}
.search-container .search-input {
  @apply w-full rounded h-[50px] pl-[15px] pr-[50px] border-[transparent];
  outline: none;
}
.search-container .search-btn {
  @apply absolute right-[10%];
}
.backdrop {
  @apply blur-[14px] pointer-events-none;
  -webkit-filter: blur(14px);
  -moz-filter: blur(14px);
  -ms-filter: blur(14px);
  -o-filter: blur(14px);
}

.resource-content {
  @apply text-xl px-4 md:px-10 py-5;
}
.resource-content-mobile {
  @apply text-xs px-10 py-5;
}
.filter-layout {
  @apply bg-neutral w-full;
}
.filter-layout header {
  @apply tracking-[0.8px] text-neutral font-bold px-5 py-[15px] rounded-[5px_5px_0px_0px];
  background: #033c7c;
}
.filter-layout .filter-header {
  @apply text-left tracking-[0.8px] text-baseColor opacity-100;
  font: normal normal bold 16px/18px Montserrat;
}
.filter-layout .filter-checkbox {
  @apply bg-neutral shadow-[0px_3px_10px_#00000029] border border-primary w-4 h-4 shrink-0 mt-0 rounded-none border-solid;
}
.filter-layout input[type="checkbox"]::before {
  @apply bg-neutral border border-primary cursor-pointer border-solid;
}
.filter-layout .filter-checkbox:checked[type="checkbox"] {
  @apply bg-[url(../../assets/images/check.svg)];
}
.filter-layout .filter-label {
  @apply text-sm tracking-[0.7px] cursor-pointer;
}
.filter-layout .filter-submit {
  @apply px-0 py-3 bg-primary/15;
}
.filter-layout .filter-submit .cancel-text {
  @apply text-primary font-bold border-[none];
  outline: none;
  background: transparent;
}
.filter-layout .filter-resource-type {
  @apply max-h-[250px] overflow-y-auto border max-w-[97%] mt-2.5 mx-auto my-0 pb-2.5 border-solid border-[rgb(230,230,230)];
}
.filter-layout .filter-resource-type-scroll {
  @apply max-h-[250px] p-4 overflow-y-scroll border max-w-[97%] mt-2.5 mx-auto my-0 pb-2.5 border-solid border-[rgb(230,230,230)];
}
.filter-layout .filter-link {
  @apply text-base font-bold text-primary cursor-pointer;
}

/* width */
.filter-layout ::-webkit-scrollbar {
  @apply w-[5px];
}

/* Track */

/* Handle */
.filter-layout ::-webkit-scrollbar-thumb {
  @apply rounded-[10px] bg-primary;
}

/* Handle on hover */
.filter-layout ::-webkit-scrollbar-thumb:hover {
  @apply bg-primary;
}
.filter-layout .contentBlock {
  @apply border max-w-[97%] mt-2.5 mx-auto my-0 pb-2.5 border-solid border-[rgb(230,230,230)];
}
.filter-layout .react-datepicker__input-container {
  @apply border border-outlineColor/50 rounded-lg;
}
.filter-layout . {
  @apply mb-2.5;
}

.res-publish-date .date-disabled .custom-date-picker {
  @apply cursor-not-allowed;
}
@media (max-width: 768px) {
  .view-resource .view-container {
    @apply px-5 py-10;
  }
  .create-resource .form-container {
    @apply p-8;
  }
}
.video-uploader-block {
  @apply flex justify-center flex-wrap gap-5;
}
.view-container p {
  @apply break-all;
}
@media (max-width: 568px) {
  .max-sm-gap-50 {
    @apply grid gap-[35px];
  }
  .filter-layout header,
  .filter-layout {
    @apply rounded-none;
  }
}
.rec-submit-btn-block {
  @apply flex justify-end;
}
@media (max-width: 768px) {
  .rec-submit-btn-block {
    @apply grid gap-5 justify-center justify-items-center;
  }
  .rec-submit-btn-block button {
    @apply m-0;
  }
}
.clear-btn {
  @apply flex justify-end;
}
.clear-btn button {
  @apply text-secondary text-sm font-bold no-underline cursor-pointer border-[none];
  background: none;
}
.h-5 {
  @apply h-2.5;
}

.resource-list {
  @apply border border-outlineColor/50 border-opacity-10 border-solid w-full !rounded-lg;
}

.resource-list .resource-heading svg {
  @apply w-4 h-4;
}

.resource-list thead tr th {
  @apply bg-primary bg-opacity-10  px-4 text-baseColor tracking-[0.8px] relative align-middle  border-solid break-words;
}
.resource-list thead tr th .resource-heading {
  @apply flex items-center min-h-[70px] justify-start text-left;
}
.resource-list tbody tr {
  @apply bg-neutral border-b border-outlineColor/50  border-opacity-10 last:justify-end last:border-0 text-baseColor px-4  h-[70px] min-h-[70px] tracking-[0.8px] relative align-middle  border-solid break-words;
}
.resource-list tbody tr td {
  @apply px-4;
}

.filter-layout .filter-resource-type {
  @apply max-h-[250px] p-4 overflow-y-auto border max-w-[97%] mt-2.5 mx-auto my-0 pb-2.5 border-solid border-[rgb(230,230,230)];
}

.filter-layout .filter-checkbox {
  @apply bg-neutral shadow-[0px_3px_10px_#00000029] border border-primary w-4 h-4 shrink-0 !mt-0 rounded-none border-solid;
}
.filter-layout input[type="checkbox"]::before {
  @apply bg-neutral border border-primary cursor-pointer border-solid;
}
.filter-layout .filter-checkbox:checked[type="checkbox"] {
  @apply bg-[url(../../assets/images/check.svg)];
}

.form-container {
  @apply bg-neutral grid gap-6;
}

.add-topic {
  @apply border border-outlineColor/50 !border-opacity-10 rounded-lg max-w-[534px] w-full mt-2;
  margin-bottom: 50px;
}
.add-topic .topic-header {
  @apply bg-primary bg-opacity-15 px-6 py-4;
}
.add-topic .container {
  @apply p-6 grid gap-6;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out;
}
