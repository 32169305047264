* {
  @apply font-body;
}

.sandbox-main-container input {
  @apply border border-outlineColor/50 px-[10px] py-0;
}

.phr-logo {
  @apply w-[186px] mr-2.5 mt-[70px] mb-[60px];
}

.mail-pera-div a {
  @apply text-primary text-lg font-bold break-words tracking-[0.59px] underline;
}
.mail-logo-div {
  @apply flex justify-center mx-auto my-0;
}

.api-link-menu,
.api-link-menu:hover {
  @apply text-center tracking-[0.14px] text-baseColor w-full block no-underline mt-[60px];
}

@media (min-width: 768px) {
  .mail-logo-div {
    @apply flex justify-center mx-auto my-0;
  }
}
@media (min-width: 300px) {
  .sandbox-container {
    @apply w-[90%] m-auto;
  }
}
@media (min-width: 576px) {
  .sandbox-container {
    @apply w-[90%] m-auto;
  }
}
@media (min-width: 768px) {
  .sandbox-container {
    @apply w-9/12 m-auto;
  }
}
@media (min-width: 992px) {
  .sandbox-container {
    @apply w-[720px] m-auto;
  }
}
.ft-logo img {
  @apply w-[210px];
}
.ft-logo {
  @apply flex flex-col items-center mt-[75px] mb-0 mx-0 pb-[60px];
}
.sandbox-main-container {
  @apply relative md:w-[90%] md:bg-neutral md:mx-auto md:my-[90px];
}
@media (min-width: 300px) {
  .sandbox-main-container {
    @apply w-[90%] bg-neutral mx-auto my-5;
  }
}
@media (min-width: 992px) {
  .sandbox-main-container {
    @apply w-[85%] bg-neutral mx-auto my-[90px];
  }
}
@media (min-width: 1200px) {
  .sandbox-main-container {
    @apply w-[1088px] bg-neutral mx-auto my-[90px];
  }
}
.sandbox-main-container .back img {
  @apply cursor-pointer absolute w-[35px] m-5 left-0 top-0;
}

.sandbox-main-container p {
  @apply text-left tracking-[0px] text-[#0d181d] leading-[normal];
  font: normal normal normal 14px/24px Montserrat;
}
.accordion-item:first-of-type .accordion-btn,
.accordion-btn {
  @apply text-neutral bg-btnPrimary shadow-[inset_0_-1px_0_rgb(0_0_0_/_13%)] text-left tracking-[0px];
  font: normal normal 600 18px/22px Montserrat;
}
/* .accordion-btn {
  @apply relative flex items-center w-full text-base text-[#212529] text-left bg-neutral px-5 py-4 rounded-none border-0 expanded:after:bg-[url("../assets/images/remove\_icon.svg")] expanded:after:-rotate-180 after:shrink-0 after:content-[""] after:bg-no-repeat after:transition-transform after:duration-[0.2s] after:ease-[ease-in-out] after:h-[30px] after:w-[30px]  after:ml-auto;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
} */
.accordion-btn[aria-expanded="false"]::after {
  @apply bg-[url("../assets/images/add\_icone.svg")] -rotate-180;
}
.accordion-body {
  @apply px-5 py-[45px];
}
.accordion-body h1 {
  @apply text-left tracking-[0.14px] mb-1;
  font: normal normal 600 14px/22px Montserrat;
}
.api-card-block {
  @apply mb-5;
}

/* api input block */
.api-input-block h1 {
  @apply text-left tracking-[0.16px] text-[#0d181d] mx-0 my-10;
  font: normal normal 600 16px/22px Montserrat;
}
.api-input-group {
  @apply block mb-[15px] last:block last:mb-0;
}
.api-input-group input {
  @apply absolute z-[-1] opacity-0;
}
.api-input-group label {
  @apply relative cursor-pointer bg-[#d1c1da] max-w-[366px] w-full min-h-[42px] flex justify-center items-center text-center tracking-[0px] text-[#0d181d];
  font: normal normal 600 16px/19px Montserrat;
}
.api-input-group input:checked + label {
  @apply bg-[#153a66] text-[#fcfeff];
}

/* api input block end */
.api-data-heading {
  @apply mt-[55px];
}
.api-data-heading h1 {
  @apply text-left tracking-[0px] text-[#11214b] mb-[15px];
  font: normal normal bold 22px/27px Montserrat;
}
.api-data-group,
.api-headers {
  @apply mt-[35px];
}
.api-data-group h1,
.api-headers h1 {
  @apply text-left tracking-[0px] text-[#0d181d] mb-[15px];
  font: normal normal 600 18px/22px Montserrat;
}
.api-base-output {
  @apply flex items-center min-h-[47px] w-full bg-neutral border p-4 border-solid border-[#cecece];
}
.api-base-output span {
  @apply text-left tracking-[0px] text-[#b20e0e] break-all;
  font: normal normal normal 14px/16px Courier New;
}
.api-auth-key-block {
  @apply grid grid-cols-[40fr_60fr] w-full mx-[30px] my-5 border-b-[#e7e7e7] border-b border-solid;
}
.api-auth-key-block h2,
.api-endpoint-block h2 {
  @apply text-left tracking-[0px] text-[#0d181d];
  font: normal normal 600 14px/18px Montserrat;
}
.api-auth-key-block h2 {
  @apply whitespace-nowrap m-0 pb-5 border-b-[#e7e7e7] border-b border-solid;
}
.api-auth-key-block p,
.api-auth-key-block input {
  @apply grid min-h-[30px] items-center w-full mx-0 my-[25px];
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.api-auth-key-block a {
  @apply text-left underline tracking-[0px] text-primary;
  font: normal normal 600 14px/18px Montserrat;
}
.api-endpoint-block {
  @apply p-10;
}
.api-endpoint-data div {
  @apply flex gap-2.5;
}
.api-endpoint-data span {
  @apply inline-flex justify-center items-center h-7 w-10 border text-left tracking-[0px] border-solid border-[#707070] first:text-neutral last:border last:border-solid last:border-[#707070];
  font: normal normal 600 14px/18px Montserrat;
}
.api-endpoint-data span:first-child {
  background: #275db2;
}
.parameter-info {
  @apply grid gap-[15px];
}
.api-parameters-block h2 {
  @apply mx-0 my-10;
}
.table > :not(:first-child) {
  @apply border-t-[#cecece] border-t border-solid;
}
.parameter-info tr {
  @apply align-baseline;
}
.parameter-info th {
  @apply text-left tracking-[0px] text-[#0d181d] h-[45px];
  font: normal normal 600 14px/18px Montserrat;
}
.parameter-info .table {
  @apply block overflow-x-auto whitespace-nowrap;
}
.parameter-info .table thead,
.parameter-info .table tbody {
  @apply max-w-[800px];

  /* display: block; */
}
.table > :not(caption) > * > * {
  @apply border-b-0;
}
.api-responses h2 {
  @apply mb-[15px];
}
.parameter-info table th:first-child,
.parameter-info table td:first-child {
  @apply pl-0;
}
.parameter-info table td {
  @apply text-left tracking-[0px] text-error;
  font: normal normal normal 14px/16px Courier New;
}
.parameter-info table td h2 {
  @apply mx-0 my-[30px];
}
.api-headers textarea {
  @apply text-xs w-full min-h-[280px] border rounded font-semibold text-[#3b4151] p-2.5 border-solid border-[#cecece];
  outline: none;
  background: hsla(0, 0%, 100%, 0.8);
  font-family: monospace;
}
.api-headers textarea:disabled {
  background: rgb(206 206 206 / 54%);
}
code[class*="language-"],
pre[class*="language-"] {
  @apply whitespace-pre-line;
}
code {
  @apply block w-auto text-[0.8em] shadow-[0_16px_40px_-5px_rgba(0,0,0,0.5)] pl-[45px] pr-[50px] py-10 rounded-[10px];
}
.api-btn {
  @apply font-bold min-h-[40px] text-btnPrimaryContent bg-btnPrimary text-sm uppercase text-center border min-w-[150px] justify-center items-center m-auto px-2 py-0 rounded-[30px] border-solid border-[#0060af];
  font-family: Montserrat, sans-serif;
}
.api-auth-btn-block {
  @apply grid gap-[15px] w-full grid-cols-[repeat(auto-fit,minmax(155px,max-content))] justify-end mt-5;
}
.block-right {
  @apply flex justify-end;
}
@media (max-width: 568px) {
  .api-auth-key-block {
    @apply gap-2.5 m-5;
  }
  .api-endpoint-block {
    @apply p-5;
  }
}
.prettier-code {
  @apply border max-h-[400px] overflow-y-scroll min-h-[400px] bg-black text-neutral p-[19px] rounded-[5px] border-solid border-[#ccc];
}
.prettier-code * {
  @apply text-neutral font-medium;
}
.custom-table table {
  @apply w-full border-collapse;
}
.custom-table table td,
.custom-table table th {
  @apply border p-2 border-solid border-[#ddd];
}
.custom-table table tr:nth-child(even) {
  @apply bg-[#f2f2f2];
}
.custom-table table tr:hover {
  @apply bg-[#ddd];
}

.custom-table table th {
  @apply text-left bg-btnPrimary text-btnPrimaryContent py-3;
}
.api-parameter-block {
  @apply flex justify-between items-center;
}

