.banner {
  @apply hidden lg:block;
  background-repeat: no-repeat;
  background-size: contain;
}
.bg-banner {
  background-image: url("../../assets/images/banner.svg") !important;
  background-repeat: repeat-x !important;
}

/* .banner {
  background-image: url('../../assets/images/banner.svg');
}
.theme-dark .banner {
  background-image: url('../../assets/images/banner-dark.svg');
}
.theme-calm .banner {
  background-image: url('../../assets/images/banner.svg');
}
.theme-dyslexia .banner {
  background-image: url('../../assets/images/banner.svg');
} */

.banner.big {
  min-height: 180px;
  background-position: bottom left;
}
@media (max-width: 991px) {
  .banner.big {
    min-height: 135px;
    background-size: auto;
  }
}
@media (max-width: 768px) {
  .banner.big {
    min-height: 90px;
  }
}
.banner.medium {
  min-height: 90px;
  background-position: bottom left;
}
.banner.small {
  min-height: 24px;

  background-position: top left;
  background-repeat-x: repeat;
}
@media (max-width: 576px) {
  .md-banner {
    min-height: 90px !important;
  }
}
