/* Button Variants */
.btn-primary {
  @apply bg-btnPrimary;
}

.btn-secondary {
  @apply bg-btnPrimaryStates;
}

.btn-green {
  @apply bg-btnPrimary text-btnPrimaryContent;
}

.btn-violet {
  @apply bg-btnPrimary text-btnPrimaryContent hover:text-btnPrimaryStates;
}

.btn:disabled {
  @apply opacity-30;
}
.react-datepicker {
  @apply shadow-[0px_3px_6px_#00000029] border-[none];
}
.react-datepicker .react-datepicker__header {
  @apply border-[none] !bg-neutral;
}
.react-datepicker .react-datepicker__day--selected {
  @apply bg-[$green] text-[$black] font-bold rounded-[50%];
}
.react-datepicker .react-datepicker__day--today {
  @apply bg-neutral border text-primary rounded-[50%] border-solid border-primary focus-visible:border focus-visible:border-solid focus-visible:border-[#035faf];
}

.acces-btn {
  @apply inline-flex items-center justify-center leading-normal text-center no-underline align-middle cursor-pointer select-none bg-transparent border text-base font-bold min-h-[50px] px-3 px-12 py-1.5 py-0 rounded-[5px] border-solid border-transparent;
  -webkit-user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.acces-btn.disabled {
  @apply opacity-30 pointer-events-none;
}
.close-icon {
  @apply w-5 h-5;
}
