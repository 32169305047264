.trust-management .list-container {
  background: #fff;
}
.trust-management .list-container .horizontal-scrolll {
  @apply overflow-x-auto mt-2;
  transform: rotateX(180deg);
}
.trust-management .list-container .scroll-content {
  transform: rotateX(180deg);
}

/* width */
.trust-management .list-container ::-webkit-scrollbar {
  @apply h-2 rounded-[15px];
  background: rgb(240, 240, 240);
}

/* Handle */
.trust-management .list-container ::-webkit-scrollbar-thumb {
  @apply rounded-[15px];
  background: rgb(3, 95, 175);
}

/* Handle on hover */
.trust-management .list-container ::-webkit-scrollbar-thumb:hover {
  background: rgb(3, 95, 175);
}
.trust-management .trust-list thead {
  @apply border-[none];
}
.trust-management .trust-list thead tr th {
  @apply bg-primary text-neutral tracking-[0.8px] py-[30px] border-b-2 border-b-outlineColor border-solid first:pl-5 last:pr-5 whitespace-nowrap;
}
.trust-management .trust-list tbody tr {
  @apply align-middle;
}
.trust-management .trust-list tbody tr:nth-child(2n + 1) td {
  @apply bg-[#f7f7f7];
}
.trust-management .trust-list tbody td {
  @apply tracking-[0.8px] py-[15px] border-[none] first:text-base first:font-bold first:text-secondary first:pl-5 last:pr-5 bg-neutral;
}
.trust-management .trust-list img {
  @apply cursor-pointer;
}
.trust-management .search-container {
  @apply flex relative;
}
.trust-management .search-container .search-transition {
  @apply transition-all duration-[0.5s] ease-[ease-in];
}
.trust-management .search-container .search-input {
  @apply w-full rounded h-[50px] pl-[15px] pr-[50px] border-[transparent];
  outline: none;
}
.trust-management .search-container .search-btn {
  @apply absolute right-[10%];
}
.user-form {
  @apply max-w-[475px] w-[90%];
}
.error {
  @apply text-error text-center;
}
.role-permission .filter-checkbox {
  @apply w-6 h-6 shadow-[inset_1px_1px_1px_#00000029] border opacity-100 cursor-pointer shrink-0 mt-0 rounded-none border-solid border-primary bg-primary border-opacity-15;
}
.role-permission .filter-checkbox:checked[type="checkbox"] {
  @apply bg-neutral border border-primary bg-center bg-[url(../../assets/images/check.svg)] border-solid;
}
.role-permission .filter-label {
  @apply text-sm tracking-[0.7px] text-[$black];
}
.copy-icon {
  @apply h-7 w-7 float-right cursor-pointer;
}
.show-border {
  @apply border-4 border-solid border-primary;
}
.hide-border {
  @apply border-[none];
}
.copy-div {
  @apply flex items-center;
}
.copy-label {
  @apply text-[12pt] float-left mb-[5px];
}
.detail-input {
  @apply bg-neutral;
}
.-cred {
  @apply mb-[27px];
}
.copied-flag {
  @apply opacity-[unset] pointer-events-none bg-primary bg-opacity-40 text-neutral border-primary;
}
input + .form-check-label,
.form-check-label {
  @apply cursor-pointer;
}
