.pagination {
  @apply flex items-center text-lg font-bold select-none text-btnPrimary tracking-[0.9px] mt-10 p-0;
  list-style: none;
}
.pagination .page-item {
  @apply min-w-[37px] min-h-[32px] flex items-center justify-center transition-all duration-[0.3s] bg-btnPrimary bg-opacity-10 mx-0.5 my-0 p-[5px] hover:cursor-pointer;
}
.pagination .page-item:hover {
  @apply bg-btnPrimary text-btnPrimaryContent;
}
.pagination .page-item.active {
  @apply text-btnPrimaryContent bg-btnPrimary;
}
.pagination .page-item.arrow-icon {
  @apply relative z-[1] text-[35px] -translate-y-0.5 transition-all duration-[0.3s] bg-transparent after:content-[""] after:w-[25px] after:h-[25px] after:absolute after:z-[-1] after:-translate-x-2/4 after:-translate-y-2/4 after:left-2/4 after:top-[56%];
}
.pagination .page-item.arrow-icon:hover {
  background: transparent;
}
.pagination .page-item.arrow-icon:hover:after {
  @apply bg-btnPrimary bg-opacity-10;
}
.pagination .page-item.arrow-icon.disabled {
  @apply cursor-not-allowed text-baseColor;
}
.pagination .page-item.arrow-icon.disabled:hover {
  background: transparent;
}
