.sec-nav-wrap {
  @apply flex items-center justify-between overflow-x-auto whitespace-nowrap border-b border-outlineColor/50 bg-neutral align-middle  lg:!bg-navbar;
}
.sec-nav-wrap::-webkit-scrollbar {
  @apply hidden;
}
.sec-nav-wrap ul {
  @apply pl-0;
}
.sec-nav-wrap ul li {
  @apply inline-block;
}
.sec-nav-wrap ul li a {
  @apply relative flex text-navbarContent items-center justify-center m-0 px-[30px] py-4 p-0 hover:cursor-pointer hover:after:border-t-[#033c7c] no-underline;
}
.sec-nav-wrap ul li a.activeMenu:after {
  @apply w-0 h-0 content-["_"] absolute mx-auto border-t-0 border-b-[10px] border-x-[10px] border-x-transparent border-solid border-primary bottom-0 inset-x-0;
}

.sec-nav-wrap > :only-child {
  @apply !mx-auto;
}
.sec-nav-item ul li {
  @apply mb-0 inline-flex text-baseColor lg:text-navbarContent;
}
.sec-nav-item ul li a {
  @apply !font-normal !text-baseColor lg:!text-navbarContent;
}

.sec-nav-item {
  @apply relative px-7  text-center font-body tracking-wide text-navbarContent !no-underline;
}
.sec-nav-item.active {
  @apply border-b-2 border-navbar !font-semibold text-navbar lg:border-b-0 lg:text-navbarContent;
}

@media (min-width: 1024px) {
  .sec-nav-item.active::after {
    content: "";
    @apply absolute left-0 right-0 bottom-0 mx-auto h-0 w-0  border-navbar;
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    border-bottom: 10px solid;
    border-top: 0;
  }
}

@media (max-width: 768px) {
  .activeMenu ~ .header-mark {
    @apply border-t-[50px] border-t-[#004E8B] border-solid;
  }
}

.header-block {
  @apply relative min-h-[220px];
}
.profile-div {
  @apply flex justify-between items-center;
}
.pri-nav-link {
  @apply font-semibold no-underline text-baseColor;
}
.logo-cav {
  @apply absolute left-0 top-0 w-[100px];
}
.header-img {
  @apply w-[165px] h-[70px] flex items-center;
}
.header-img svg {
  @apply text-baseColor;
}
.menu-block {
  @apply flex items-center;
}
.profile-dropdown {
  @apply relative mx-3 my-6;
}
.profile-dropdown .my-account-web a,
.profile-dropdown .my-account-mobile a {
  @apply text-baseColor font-semibold;
}
.profile-dropdown .my-account-web .dropdown-menu-dark {
  @apply bg-neutral border border-[bg-neutral] border-solid;
}
.profile-dropdown .my-account-web .dropdown-menu-dark .dropdown-item {
  @apply border-b-[bg-neutral] px-2 py-2.5 border-b border-solid last:border-b-[none] hover:bg-[$primary-blue] hover:text-neutral;
}
.header-info-section {
  @apply max-w-[80%] w-[90%] text-center mx-auto pb-10;
}
.header-info-section h1,
.header-info-section p {
  @apply tracking-widest font-normal text-baseColor mb-3;
}
.header-info-section h1 {
  @apply text-[32px] leading-[39px] font-bold;
  font-family: Dosis, sans-serif !important;
}
.header-info-section p {
  @apply text-base leading-6 tracking-wide;
}

.profileNav > path {
  @apply text-neutral lg:text-headerIcon;
}

.dropdown-group {
  @apply relative;
}
/* .dropdown-group.profile {
  @apply border-b border-titleColor pb-1;
} */
.dropdown-toggle {
  @apply relative m-0 inline-block h-[30px] w-[30px] cursor-pointer border-0 bg-transparent py-2 pr-7 text-center font-normal text-baseColor outline-none lg:h-9 lg:w-9;
}

.nav-group {
  @apply z-[999] flex min-h-[52px] w-full items-center justify-between bg-navbar px-4 !py-1 lg:relative lg:bg-transparent  lg:py-0 lg:px-10;
}

.just-w {
  @apply lg:w-[190px] xl:min-w-[290px];
}

.pri-nav-wrap {
  @apply hidden justify-start overflow-x-auto whitespace-nowrap text-center sm:justify-center sm:whitespace-normal lg:flex;
  -webkit-overflow-scrolling: touch;
}

.pri-nav-wrap ul {
  @apply flex flex-wrap items-center justify-center;
}
.pri-nav-wrap ul li {
  @apply m-0;
}

.pri-nav-link {
  @apply m-0 whitespace-nowrap px-4 font-header text-baseColor no-underline transition duration-200 ease-in-out hover:text-titleColor;
}

/* Navigation new colour */
.top-nav-block {
  @apply grid gap-7 bg-cover bg-center bg-no-repeat px-0 py-6  transition duration-200 ease-in-out md:mt-0 lg:mt-0 lg:gap-10;
}
.pri-nav-wrap .pri-nav-link:last-child {
  @apply border-0;
}
.top-nav-block .pri-nav-link {
  @apply border-0 border-headerContent font-semibold text-headerContent text-opacity-80 !no-underline;
}
.top-nav-block .pri-nav-link:hover {
  @apply font-semibold text-headerContent;
}
.top-nav-block .pri-nav-link.active {
  @apply font-semibold text-headerContent;
}
.top-nav-block h2 {
  @apply !text-headerContent;
}
/* .top-nav-block .nav-desc {
  @apply text-primaryContent;
} */
.top-nav-block .dropdown-toggle {
  @apply p-0 text-primaryContent;
}
.top-nav-block .dropdown-toggle::after {
  @apply hidden text-primaryContent;
}
.top-nav-block .dropdown-group.profile {
  @apply flex items-center border-0 border-neutral p-0;
}
.top-nav-block .dropdown-group.profile ul {
  @apply grid gap-2;
}
.top-nav-block .dropdown-group.profile li {
  @apply m-0 hover:bg-primary/5;
}
.dropdown-group.profile li a,
.dropdown-group.profile li button {
  @apply m-0 text-baseColor text-base hover:bg-primary/5;
}

.dropdown-group .dropdown-menu {
  @apply min-w-[200px] max-w-[250px] pt-4 pb-2;
}

/* Mobile */
.mobile-top-nav-block .dropdown-toggle {
  @apply p-0 text-primaryContent;
}
.mobile-top-nav-block .dropdown-toggle::after {
  @apply hidden text-primaryContent;
}
.mobile-top-nav-block .dropdown-group.profile {
  @apply flex items-center border-0 border-neutral p-0;
}
.mobile-top-nav-block .dropdown-group.profile ul {
  @apply grid gap-2;
}
.mobile-top-nav-block .dropdown-group.profile li {
  @apply m-0 hover:bg-primary hover:bg-opacity-5;
}

.bento-icon {
  @apply text-primary;
}
.top-nav-block .bento-icon {
  @apply h-6 w-6 text-primaryContent lg:h-9 lg:w-9;
}
.bento-icon.close-icon {
  @apply lg:h-7;
}
.top-nav-block .notify-icon {
  @apply text-primaryContent;
}
@media (max-width: 768px) {
  .top-nav-block .bento-icon {
    @apply text-headerIcon;
  }
  .top-nav-block .notify-icon {
    @apply text-headerIcon;
  }
}

.mobile-top-nav-block {
  @apply bg-card px-0 transition duration-200 ease-in-out;
}

.header-text {
  @apply mx-auto grid max-w-3xl gap-4 p-4 text-center;
}
.header-text h2 {
  @apply !text-headerContent;
}
.header-text p {
  @apply !text-headerContent;
}

/*Dropdown*/
.dropdown-group {
  @apply relative;
}
.dropdown-group.profile {
  @apply border-b border-titleColor pb-1;
}
.dropdown-toggle {
  @apply relative m-0 inline-block h-[30px] w-[30px] cursor-pointer border-0 bg-transparent py-2 pr-7 text-center font-normal text-baseColor outline-none lg:h-9 lg:w-9;
}
.dropdown-toggle::after {
  @apply absolute right-2 top-4 inline h-0 w-0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
}
.dropdown-menu {
  @apply absolute right-0 top-6 z-10 mt-3 rounded-xl  border border-disabled bg-neutral py-2 text-left shadow;
  min-width: 150px;
  max-width: 250px;
}
.dropdown-menu.hide {
  @apply hidden;
}
.dropdown-item {
  @apply m-0 block py-1 pl-4 pr-2 text-baseColor !no-underline;
  box-sizing: border-box;
}

.u_name {
  @apply border-b border-outlineColor/50 px-4 pb-4 font-bold hover:!bg-transparent;
}
