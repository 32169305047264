@tailwind base;

@layer base {
  h1 {
    @apply font-header  text-4xl font-normal !text-titleColor  md:text-6xl;
  }
  h2 {
    @apply font-header text-3xl font-normal !text-titleColor  md:text-5xl;
  }
  h3 {
    @apply font-body text-lg font-semibold text-titleColor  md:text-xl;
  }
  h4 {
    @apply font-body text-lg font-medium text-baseColor;
  }
  h5 {
    @apply font-body text-base font-semibold text-baseColor;
  }
  p,
  span,
  ul,
  li,
  legend {
    @apply font-body text-sm font-normal text-baseColor  sm:text-base;
  }
  div {
    @apply font-body text-sm text-baseColor  sm:text-base;
  }
  b {
    @apply font-body text-sm text-baseColor  sm:text-base;
  }
  button {
    @apply text-base;
  }
  a {
    @apply text-sm text-primary underline  hover:underline sm:text-base;
  }
  li > a {
    @apply text-base text-primary  hover:underline sm:text-lg;
  }
  small {
    @apply text-sm text-baseColor;
  }
  ul li::marker {
    @apply text-titleColor;
  }

  p:contains("@") {
    @apply !lowercase first-letter:!lowercase;
  }
  input:contains("@"),
  optgroup:contains("@"),
  select:contains("@"),
  textarea:contains("@"),
  div:contains("@") {
    @apply !lowercase first-letter:!lowercase;
  }

  input,
  optgroup,
  select,
  textarea {
    @apply !text-baseColor;
  }
  ::placeholder,
  .react-select__placeholder,
  .css-1jqq78o-placeholder {
    @apply !font-body !text-base tracking-normal !text-baseColor text-opacity-90;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #00001e !important;
  }

  /* CSS to hide the title element on hover */
  svg title:hover {
    visibility: hidden !important;
  }
  /* CSS to hide the title element on hover */
  button svg title {
    display: none;
  }

  svg title::-webkit-details-marker {
    display: none;
  }
}

.theme-default {
  /* --banner: url('../../assets/images/banner.svg'); */
  /* --pattern: url('../../assets/images/Maia_header.webp');
    --tick: url('../../assets/images/tick-white.svg'); */
  --primary: 4 94 179;
  --primary-content: 255 255 255;
  --secondary: 255 255 255;
  --secondary-content: 4 94 179;
  --accent: 23 133 112;
  --accent-content: 255 255 255;

  --button-primary: 4 94 179;
  --btn-primary-content: 255 255 255;
  --btn-primary-states: 3 60 124;
  --button-secondary: 255 255 255;
  --btn-secondary-content: 4 94 179;
  --btn-secondary-states: 3 60 124;
  --navbar: 3 60 124;
  --navbar-content: 255 255 255;
  --header: 248 248 248;
  --header-content: 49 49 49;
  --header-icon: 3 60 124;

  --baseColor: 49 49 49;
  --titleColor: 4 94 179;
  --body: 248 248 248;
  --card: 248 248 248;

  --neutral: 255 255 255;
  --outline: 191 191 191;
  --info: 37 99 235;
  --success: 18 110 52;
  --warning: 255 95 73;
  --error: 220 38 38;
  --disabled: 233 236 239;
  --transparent: transparent;
  --mood-verypoorly: #a20000;
  --mood-poor: #cf4747;
  --mood-alright: #1b67bc;
  --mood-well: #0d7e94;
  --mood-verywell: #16846f;
  --font-header: "Montserrat", "sans-serif";
  --font-body: "Montserrat", "sans-serif";
}
.theme-default-clinician {
  /* --banner: url('../../assets/images/banner.svg'); */
  --primary: 70 189 105;
  --primary-content: 255 255 255;
  --secondary: 255 255 255;
  --secondary-content: 70 189 105;
  --accent: 55 165 144;
  --accent-content: 255 255 255;
  --button-primary: 70 189 105;
  --btn-primary-content: 255 255 255;
  --btn-primary-states: 0 138 40;
  --button-secondary: 255 255 255;
  --btn-secondary-content: 70 189 105;
  --btn-secondary-states: 0 138 40;
  --navbar: 255 255 255;
  --navbar-content: 49 49 49;
  --header: 248 248 248;
  --header-content: 49 49 49;
  --header-icon: 70 189 105;
  --baseColor: 49 49 49;
  --titleColor: 70 189 105;
  --body: 248 248 248;
  --card: 248 248 248;
  --neutral: 255 255 255;
  --outline: 191 191 191;
  --info: 242 177 14;
  --success: 70 189 105;
  --warning: 242 177 14;
  --error: 223 87 87;
  --disabled: 233 236 239;
  --transparent: transparent;
  --mood-verypoorly: 249 159 44;
  --mood-poor: 255 218 71;
  --mood-alright: 132 172 217;
  --mood-well: 164 188 57;
  --mood-verywell: 75 159 136;
  --font-header: 'Montserrat', 'sans-serif';
  --font-body: 'Montserrat', 'sans-serif';
}
.Dark {
  /* --banner: url('../../assets/images/banner.svg'); */
  /* --pattern: url('../../assets/images/Maia_header.png');
    --tick: url('../../assets/images/tick.svg'); */
  --primary: 187 134 252;
  --primary-content: 42 42 42;
  --secondary: 88 88 88;
  --secondary-content: 187 134 252;
  --accent: 0 196 180;
  --accent-content: 255 255 255;

  --button-primary: 187 134 252;
  --btn-primary-content: 42 42 42;
  --btn-primary-states: 178 115 255;
  --button-secondary: 88 88 88;
  --btn-secondary-content: 187 134 252;
  --btn-secondary-states: 178 115 255;
  --navbar: 178 115 255;
  --navbar-content: 42 42 42;
  --header: 33 31 32;
  --header-content: 199 199 204;
  --header-icon: 178 115 255;

  --baseColor: 199 199 204;
  --titleColor: 187 134 252;
  --body: 33 31 32;
  --card: 33 31 32;
  --neutral: 42 42 42;
  --outline: 191 191 191;
  --info: 37 99 235;
  --success: 18 110 52;
  --warning: 255 95 73;
  --error: 220 38 38;
  --disabled: 108 108 108;
  --transparent: transparent;
  --mood-verypoorly: #df5757;
  --mood-poor: #f2c00e;
  --mood-alright: #1b67bc;
  --mood-well: #17a2b8;
  --mood-verywell: #37a590;
  --font-header: "Montserrat", "sans-serif";
  --font-body: "Montserrat", "sans-serif";
}

.Dyslexia {
  /* --banner: url('../../assets/images/banner.svg'); */
  /* --pattern: url('../../assets/images/Maia_header.png');
    --tick: url('../../assets/images/tick-white.svg'); */
  --primary: 18 137 47;
  --primary-content: 255 255 255;
  --secondary: 54 155 123;
  --secondary-content: 24 213 234;
  --accent: 34 64 112;
  --accent-content: 255 255 255;

  --button-primary: 18 137 47;
  --btn-primary-content: 255 255 255;
  --btn-primary-states: 13 106 36;

  --button-secondary: 54 155 123;
  --btn-secondary-content: 18 137 47;
  --btn-secondary-states: 13 106 36;

  --navbar: 13 106 36;
  --navbar-content: 255 255 255;
  --header: 248 248 248;
  --header-content: 49 49 49;
  --header-icon: 13 106 36;

  --baseColor: 11 24 30;
  --titleColor: 18 137 47;
  --body: 247 247 247;
  --card: 247 247 247;

  --neutral: 255 255 255;
  --outline: 191 191 191;
  --info: 37 99 235;
  --success: 18 110 52;
  --warning: 255 95 73;
  --error: 220 38 38;
  --disabled: 233 236 239;
  --transparent: transparent;
  --mood-verypoorly: #df5757;
  --mood-poor: #f2c00e;
  --mood-alright: #1b67bc;
  --mood-well: #17a2b8;
  --mood-verywell: #37a590;
  --font-header: "Montserrat", "sans-serif";
  --font-body: "Montserrat", "sans-serif";
}

.Visibility {
  /* --banner: url('../../assets/images/banner.svg'); */
  /* --pattern: url('../../assets/images/Maia_header.png');
    --tick: url('../../assets/images/tick-white.svg'); */
  --primary: 0 122 194;
  --primary-content: 255 255 255;
  --secondary: 255 255 255;
  --secondary-content: 0 122 194;
  --accent: 255 157 0;
  --accent-content: 11 24 30;

  --button-primary: 0 122 194;
  --btn-primary-content: 255 255 255;
  --btn-primary-states: 2 102 160;
  --button-secondary: 255 255 255;
  --btn-secondary-content: 0 122 194;
  --btn-secondary-states: 2 102 160;
  --navbar: 2 102 160;
  --navbar-content: 255 255 255;
  --header: 248 248 248;
  --header-content: 49 49 49;
  --header-icon: 2 102 160;

  --baseColor: 11 24 30;
  --titleColor: 0 122 194;
  --body: 243 242 252;
  --card: 243 242 252;

  --neutral: 255 255 255;
  --outline: 191 191 191;
  --info: 37 99 235;
  --success: 18 110 52;
  --warning: 255 95 73;
  --error: 220 38 38;
  --disabled: 233 236 239;
  --transparent: transparent;
  --mood-verypoorly: #df5757;
  --mood-poor: #f2c00e;
  --mood-alright: #1b67bc;
  --mood-well: #17a2b8;
  --mood-verywell: #37a590;
  --font-header: "Montserrat", "sans-serif";
  --font-body: "Montserrat", "sans-serif";
}
