.profile-section {
  @apply bg-neutral;
}
.profile-block {
  @apply max-w-[600px] w-[90%] m-auto px-0 py-[70px];
}
.profile-block form {
  @apply mt-[35px];
}
. .psw-link-block {
  @apply flex justify-end;
}
.psw-link-block span {
  @apply text-primary cursor-pointer underline font-medium;
}
